import React, { useEffect, Fragment } from 'react';
import { graphql, navigate } from 'gatsby';
import { Box, Container, Text, Flex, VStack } from '@chakra-ui/react';
import CanonicalLink from 'timelesstime-ui/components/canonical-link';
import SEO from 'timelesstime-ui/components/seo';
import { KnowledgebaseLeadInHeader } from 'timelesstime-ui/components/lead-in-header';
import Pagination from 'timelesstime-ui/components/pagination';
import NewsletterSignup from 'timelesstime-ui/components/newsletter-signup';
import CallMeBack from 'timelesstime-ui/components/call-me-back';
import Heading from 'timelesstime-ui/components/heading';
import ButtonLink from 'timelesstime-ui/components/button-link';
import Jumbotron from 'timelesstime-ui/components/jumbotron';
import PageLayout from '../components/layout';
import KnowledgebaseCard from '../components/knowledgebase-card';

const KnowledgebasePage = ({
  pageContext,
  data: { allContentfulKnowledge, knowledgebasePage },
}) => {
  const {
    page: currentPage,
    numPages,
    firstPageUrl,
    prevPageUrl,
    nextPageUrl,
    lastPageUrl,
  } = pageContext;

  // redirect to /search if there are any search query params
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.get('search')) {
        urlParams.set('query', urlParams.get('search'));
        urlParams.delete('search');
        navigate(`/search?${urlParams.toString()}`, { replace: true });
      }
    }
  }, []);

  const knowledgePosts = allContentfulKnowledge.nodes;

  return (
    <PageLayout
      leadInHeader={
        <KnowledgebaseLeadInHeader
          heading={knowledgebasePage.heading || knowledgebasePage.title}
          crumbs={[
            {
              url: '/knowledgebase/',
              title: knowledgebasePage.title,
            },
          ]}
        />
      }
    >
      <SEO
        title={knowledgebasePage.title}
        keywords={knowledgebasePage.fields.keywords}
        description={knowledgebasePage.metaDescription}
        canonicalPath={`/knowledgebase/${
          currentPage > 1 ? `${currentPage}/` : ''
        }`}
      />

      <Container as="section" mt={4} mb={4} maxW="container.lg">
        Looking for something specific?{' '}
        <CanonicalLink color="orange.500" to="/search">
          Search our Knowledgebase
        </CanonicalLink>
        .
      </Container>

      <Container as="section" mt={4} mb={8} maxW="container.lg">
        <VStack spacing={12}>
          {knowledgePosts.map((knowledge, index) => (
            <Fragment key={knowledge.id}>
              {knowledgePosts.length > 3 &&
                index === Math.floor(knowledgePosts.length / 2) && (
                  <Flex justifyContent="center">
                    <ButtonLink
                      colorScheme="orange"
                      variant="outline"
                      to="/search/"
                    >
                      Looking for something specific? Search our Knowledgebase
                    </ButtonLink>
                  </Flex>
                )}
              <KnowledgebaseCard
                knowledgebaseArticle={knowledge}
                imageOnRight={index % 2 === 0}
              />
            </Fragment>
          ))}
        </VStack>
        {knowledgePosts.length < 1 && (
          <Box>
            <Heading as="h1">Oops, it looks like nothing was found.</Heading>
            <Text>
              Not a problem!{' '}
              <CanonicalLink color="orange.500" to="/contact/">
                Get in touch
              </CanonicalLink>
              , we&apos;d love to help you.
            </Text>
          </Box>
        )}
      </Container>

      <Box as="aside" mt={10} mb={10}>
        <Pagination
          currentPage={currentPage}
          numPages={numPages}
          firstPageUrl={firstPageUrl}
          prevPageUrl={prevPageUrl}
          nextPageUrl={nextPageUrl}
          lastPageUrl={lastPageUrl}
        />
      </Box>

      <Box as="footer" textAlign="center" my={12}>
        <CallMeBack size="lg" callBackText="Specific question? Get in touch" />
      </Box>

      <Jumbotron as="aside" py={8} bg="tt.darkBlue">
        <NewsletterSignup />
      </Jumbotron>
    </PageLayout>
  );
};

export const query = graphql`
  query KnowledgebasePageQuery($skip: Int!, $limit: Int!) {
    knowledgebasePage: contentfulPage(slug: { eq: "knowledgebase" }) {
      title
      slug
      heading
      metaDescription
      jsonLdFeaturedImage: featuredImage {
        ...JsonLdFeaturedImage
      }
      jsonLdThumbnailImage: featuredImage {
        ...JsonLdThumbnailImage
      }
      fields {
        path
        url
        writtenAt
        isNew
        isRecent
        keywords
      }
    }
    allContentfulKnowledge(
      sort: { fields: [updatedAt, publishDate, createdAt], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        id
        title
        slug
        title
        knowledgeType
        createdAt
        updatedAt
        publishDate
        metaDescription
        videoUrl
        metaDescription
        featuredImage {
          ...FeaturedImage
        }
        jsonLdFeaturedImage: featuredImage {
          ...JsonLdFeaturedImage
        }
        jsonLdThumbnailImage: featuredImage {
          ...JsonLdThumbnailImage
        }
        authors {
          id
          firstName
          lastName
          email
          jobTitle
        }
        fields {
          path
          url
          plaintextContent
          readingTimeMins
          readingTimeText
          writtenAt
          isNew
          isRecent
          keywords
          excerptHtml
        }
      }
    }
  }
`;

export default KnowledgebasePage;
