import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { paramCase } from 'param-case';
import { Divider, Box } from '@chakra-ui/react';
import ContentCard from 'timelesstime-ui/components/content-card';
import ButtonLink from 'timelesstime-ui/components/button-link';
import KnowledgeMetaHeader from '../knowledge-meta-header';
import KnowledgeStructuredData from '../structured-data/knowledge';

const readMoreText = (knowledgeType) => {
  switch (paramCase(knowledgeType || '')) {
    case 'article':
      return 'Read the full Article';
    case 'white-paper':
      return 'Read the full White Paper';
    case 'opinion':
      return 'Read the full Knowledgebase article';
    case 'audio-visual':
      return 'Watch the video now';
    default:
      return `Read the full Knowledgebase article`;
  }
};

const KnowledgebaseCard = ({ knowledgebaseArticle, ...props }) => {
  const updatedAtDateAsDate = new Date(knowledgebaseArticle.updatedAt);
  const createdAtDateAsDate = new Date(knowledgebaseArticle.createdAt);
  const writtenAtDateAsDate = new Date(knowledgebaseArticle.fields.writtenAt);
  return (
    <ContentCard
      url={knowledgebaseArticle.fields.path}
      title={
        <Box>
          <ContentCard.Heading to={knowledgebaseArticle.fields.path} mb={2}>
            {knowledgebaseArticle.title}
          </ContentCard.Heading>
          <KnowledgeMetaHeader knowledgebaseArticle={knowledgebaseArticle} />
          <Divider mt={2} mb={4} />
        </Box>
      }
      image={
        <GatsbyImage
          image={getImage(knowledgebaseArticle.featuredImage)}
          alt={knowledgebaseArticle?.featuredImage?.title}
        />
      }
      structuredData={
        <KnowledgeStructuredData
          slug={knowledgebaseArticle.slug}
          knowledgeType={knowledgebaseArticle.knowledgeType}
          description={knowledgebaseArticle.metaDescription}
          title={knowledgebaseArticle.title}
          createdAt={createdAtDateAsDate}
          writtenAt={writtenAtDateAsDate}
          updatedAt={updatedAtDateAsDate}
          plaintextContent={knowledgebaseArticle.fields.plaintextContent}
          authors={knowledgebaseArticle.authors}
          videoUrl={knowledgebaseArticle.videoUrl}
          thumbnailImage={knowledgebaseArticle.jsonLdThumbnailImage}
          featuredImage={knowledgebaseArticle.jsonLdFeaturedImage}
          readingTimeMins={knowledgebaseArticle.fields.readingTimeMins}
          shortMeta
        />
      }
      excerpt={knowledgebaseArticle.fields.excerptHtml}
      moreText={
        <ButtonLink
          size="sm"
          variant="solid"
          colorScheme="orange"
          width="auto"
          to={knowledgebaseArticle.fields.path}
          mt={4}
        >
          {readMoreText(knowledgebaseArticle.knowledgeType)}
        </ButtonLink>
      }
      {...props}
    />
  );
};

export default KnowledgebaseCard;
